@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes sparkle {
  0%, 100% { opacity: 0; transform: scale(0) rotate(0deg); }
  50% { opacity: 1; transform: scale(1) rotate(180deg); }
}

.sparkle-container {
  position: relative;
}

.sparkles {
  position: absolute;
  top: -20px;
  left: -20px;
  right: -20px;
  bottom: -20px;
  pointer-events: none;
  overflow: hidden;
}

.sparkle {
  position: absolute;
  width: var(--size);
  height: var(--size);
  top: var(--top);
  left: var(--left);
  animation: sparkle 2s infinite;
  animation-delay: var(--animation-delay);
}

.sparkle::before {
  content: '★';
  display: block;
  width: 100%;
  height: 100%;
  font-size: var(--size);
  line-height: 1;
  color: var(--color);
  text-shadow: 0 0 3px var(--color);
}

.sparkle:nth-child(3n) {
  --color: #FFD700; /* Gold */
}

.sparkle:nth-child(3n+1) {
  --color: #FFA500; /* Orange */
}

.sparkle:nth-child(3n+2) {
  --color: #FF8C00; /* Dark Orange */
}


.sparkling-placeholder {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  background-color: #ffffff; /* Tailwind's gray-200 */
  border-radius: 0.5rem; /* 8px */
}




@media (orientation: portrait) {
  .explanation {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .explanation .illustration {
    margin-bottom: 1rem;
    width: 70%;
    max-width: 195px;
  }
  .explanation .text-content {
    width: 100%;
    text-align: center;
    padding-left: 1rem;
    padding-right: 1rem;
    max-width: 600px;
    margin: 0 auto;
  }
}

@media (orientation: landscape) {
  .explanation {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
  }
  .explanation .illustration {
    width: 195px; /* Set a fixed width for landscape mode */
    flex-shrink: 0; /* Prevent the image from shrinking */
  }
  .explanation .text-content {
    flex: 1;
  }
}

.illustration {
  aspect-ratio: 1 / 1;
  overflow: hidden;
}

.illustration img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}



.illustration {
  width: 130%;
  max-width: 195px;
  aspect-ratio: 1 / 1;
  overflow: hidden;
}

.illustration img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .illustration {
    width: 70%;
    max-width: 195px;
    margin: 0 auto;
  }
  
  .explanation .text-content {
    padding-left: 1.5rem;  /* Increase left padding for smaller screens */
    padding-right: 1.5rem; /* Increase right padding for smaller screens */
  }
}

/* Additional adjustment for very small screens */
@media (max-width: 380px) {
  .explanation .text-content {
    padding-left: 1rem;  /* Slightly reduce padding for very small screens */
    padding-right: 1rem; /* Slightly reduce padding for very small screens */
  }
}

@media (max-width: 640px) {
  .menu-overlay {
      left: 1rem;
      right: 1rem;
      bottom: 5rem;
      max-width: none;
  }

  .menu-overlay ul {
      padding-bottom: 1rem;
  }

  .menu-overlay a {
      padding: 0.75rem 1rem;
  }
}

@media (max-width: 640px) {
  .subscription-overlay {
    padding: 1rem;
  }

  .subscription-overlay > div {
    max-width: none;
    width: calc(100% - 2rem);
  }

  .subscription-overlay h2 {
    font-size: 1.25rem;
  }

  .subscription-overlay p,
  .subscription-overlay button {
    font-size: 1rem;
  }
}

.subscription-overlay,
.enlarged-image-overlay {
  z-index: 50;
}

.menu-button,
.export-button {
  z-index: 40;
}

.enlarged-image-overlay {
  touch-action: none;
}

.enlarged-image-overlay img {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}



